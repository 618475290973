// px转rem
function pxTurnRem(pxStr) {
    var page = document.documentElement;
    page.style.padding = 0;
    page.style.margin = 0;
    document.body.style.padding = 0;
    document.body.style.margin = 0;
    var pageW = document.body.clientWidth;
    var fz = (pageW / pxStr) * 100;
    page.style.fontSize = fz + 'px';
}
pxTurnRem(750)